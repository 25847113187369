import axios from "axios";
import {
  axiosInstanceForFile,
  axiosInstance,
  axiosInstanceUnauth,
} from "../../AxiosInstance";
export const fetchVideos = async (page, pageSize, searchQuery) => {
  try {
    const response = await axiosInstance.get(
      `/videos/fetchvideosauth//${page}/${pageSize}?search=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const fetchVideosByRole = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/videos/fetchVideosByRole/${params?.page}/${params?.limit}`,
      {
        params: {
          search: params?.searchQuery, // Query parameter for search
          statusFilter: params?.statusFilter, // Query parameter for status filter
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const fetchLatestVideos = async () => {
  try {
    const response = await axiosInstance.get(`videos/fetchlatestVideos`);

    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const fetchVideosForAdmin = async (params) => {
  try {
    const response = await axiosInstance.get(
      `/videos/supervideolisting/${params?.page}/${params?.limit}`,
      {
        params: {
          search: params?.searchQuery, // Query parameter for search
          statusFilter: params?.statusFilter, // Query parameter for status filter
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
    throw error; // Propagate the error if necessary
  }
};

export const fetchVideosTags = async () => {
  try {
    const response = await axiosInstance.get(`/videos/fetchvideotags`);
    return response.data;
  } catch (error) {
    console.error("Error fetching video tags:", error);
    throw error; // Re-throw the error so React Query can handle it
  }
};

export const createVideoTag = async (tagData) => {
  try {
    const response = await axiosInstance.post(`/videos/createVideoTags`, [
      tagData,
    ]);
    return response.data;
  } catch (error) {
    console.error("Error creating video tag:", error);
    throw error;
  }
};

export const fetchUnauthVideos = async (page, pageSize, searchQuery) => {
  try {
    const response = await axiosInstanceUnauth.get(
      `/videos/fetchvideos/${page}/${pageSize}?search=${searchQuery}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};

export const fetchVideo = async (id) => {
  try {
    const response = await axiosInstanceUnauth.get(`/videos/fetchvideo/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};
export const fetchVideoAouth = async (id) => {
  try {
    const response = await axiosInstance.get(`/videos/fetchvideoAuth/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching videos:", error);
  }
};
export const makeVideoFavorite = async (id, isFavorite) => {
  try {
    const response = await axiosInstance.post(
      "/videos/favoritevideo",
      { videoId: id, isFavorite: isFavorite },
      {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("authToken"),
        },
      }
    );

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding video to favorites:", error);
  }
};

export const uploadVideo = async (data) => {
  try {
    const response = await axiosInstanceForFile.post("/videos/upload", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    console.log("Error uploading video:", error.response);
  }
};

export const updateVideo = async (data, id) => {
  try {
    const response = await axiosInstance.put(`/videos/update/${id}`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("authToken"),
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error) {
    // Handle errors
    console.log("Error updating video:", error.response);
  }
};

export const deleteVideo = async (id) => {
  try {
    const response = await axiosInstance.delete(`/videos/deleteVideo/${id}`, {
      headers: {
        Authorization: "Bearer" + localStorage.getItem("authToken"),
      },
    });

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error deleting video.:", error);
  }
};

export const deleteVideoTag = async (id) => {
  try {
    const response = await axiosInstance.delete(`/videos/deleteVideoTag/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting video tag:", error);
    throw error;
  }
};

export const updateVideoStatus = async (videoId, isVerified) => {
  try {
    const response = await axiosInstance.put(
      `/videos/verifyVideo/${videoId}`,
      {
        isVerified: isVerified,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      }
    );

    console.log("Video status update response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating video status:", error);
    throw error;
  }
};

export const generateTraslatedVideo = async () => {
  try {
    const url = "https://api.creatomate.com/v1/renders";
    const apiKey =
      "05271344b6774080b2d739495c4590de71461297d7c981a7d7802a59364ba0609b9628307158cb47b9ca63397b2f05a7";

    const data = {
      template_id: "361b8f70-dda1-434e-9652-6a95381844eb",
      modifications: {
        "Music.source":
          "https://creatomate.com/files/assets/b5dc815e-dcc9-4c62-9405-f94913936bf5",
        "Background-1.source":
          "https://creatomate.com/files/assets/4a7903f0-37bc-48df-9d83-5eb52afd5d07",
        "Text-1.text":
          "Did you know you can automate TikTok, Instagram, and YouTube videos? 🔥",
        "Background-2.source":
          "https://creatomate.com/files/assets/4a6f6b28-bb42-4987-8eca-7ee36b347ee7",
        "Text-2.text":
          "Use any video automation tool to replace these text and background assets with your own! 😊",
        "Background-3.source":
          "https://creatomate.com/files/assets/4f6963a5-7286-450b-bc64-f87a3a1d8964",
        "Text-3.text":
          "Learn how to get started on the Guides & Tutorials page on Creatomate's home page.",
        "Background-4.source":
          "https://creatomate.com/files/assets/36899eae-a128-43e6-9e97-f2076f54ea18",
        "Text-4.text":
          "Use the template editor to completely customize this video to meet your own needs. 🚀",
      },
    };

    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      }
    });
    
    console.log(response.data);
    return response.data;
    
  } catch (error) {
    console.error("Error generating video thumbnail:", error);
    throw error;
  }
};
