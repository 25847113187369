import React, { useEffect, useState } from "react";
import { m } from "framer-motion";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { MainHeader } from "./layout/mainHeader";
import { fetchUnauthVideos, fetchVideos } from "./APIS/videos";
import { useQuery } from "react-query";
import { useSearch } from "./context/SearchContext";
import { CardComponent } from "./Card";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import { alpha } from "@mui/material/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MosqueIcon from "@mui/icons-material/Mosque";
import LightModeIcon from "@mui/icons-material/LightMode";
import BrightnessLowIcon from "@mui/icons-material/BrightnessLow"; // Dawn
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh"; // Noon
import BrightnessmediumIcon from "@mui/icons-material/BrightnessMedium"; // Afternoon
import Brightness4Icon from "@mui/icons-material/Brightness4"; // Dusk
import Brightness3Icon from "@mui/icons-material/Brightness3"; // Night
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const prayerIcons = (key) => {
  switch (key) {
    case "Fajr":
      return <BrightnessLowIcon sx={{ fontSize: 24 }} />;

    case "Dhuhr":
      return <BrightnessHighIcon sx={{ fontSize: 24 }} />;
    case "Asr":
      return <BrightnessmediumIcon sx={{ fontSize: 24 }} />;
    case "Maghrib":
      return <Brightness4Icon sx={{ fontSize: 24 }} />;
    case "Isha":
      return <Brightness3Icon sx={{ fontSize: 24 }} />;
    default:
      return <Brightness3Icon sx={{ fontSize: 24 }} />;
  }
};
const getPrayerGradient = (key) => {
  switch (key) {
    case "Fajr":
      return "linear-gradient(135deg, #e3f2fd 0%, #bbdefb 100%)";
    case "Sunrise":
      return "linear-gradient(135deg, #fff3e0 0%, #ffe0b2 100%)";
    case "Dhuhr":
      return "linear-gradient(135deg, #fffde7 0%, #fff9c4 100%)";
    case "Asr":
      return "linear-gradient(135deg, #fff3e0 0%, #ffccbc 100%)";
    case "Sunset":
      return "linear-gradient(135deg, #fbe9e7 0%, #ffccbc 100%)";
    case "Maghrib":
      return "linear-gradient(135deg, #e8eaf6 0%, #c5cae9 100%)";
    case "Isha":
      return "linear-gradient(135deg, #e8eaf6 0%, #9fa8da 100%)";
    default:
      return "linear-gradient(135deg, #f5f5f5 0%, #eeeeee 100%)";
  }
};

const StyledHero = styled("div")(({ theme }) => ({
  position: "relative",
  color: "#fff",
  padding: theme.spacing(8, 0),
  textAlign: "center",
  height: "60vh",
  minHeight: "500px",
  maxHeight: "800px",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    height: "50vh",
    minHeight: "400px",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.3))",
    zIndex: 1,
  },
}));

const VideoBackground = styled("video")({
  position: "absolute",
  right: 0,
  bottom: 0,
  minWidth: "100%",
  minHeight: "100%",
  width: "auto",
  height: "auto",
  zIndex: 0,
  objectFit: "cover",
});

const StyledCategory = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  textAlign: "center",
}));

const StyledVideoCard = styled(Box)(({ theme }) => ({
  border: "1px solid #ddd",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const MotionGrid = motion(Grid);
// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const StyledSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  position: "relative",
}));

// Floating Donate Button
const FloatingDonateButton = styled(motion.div)(({ theme }) => ({
  position: "fixed",
  right: 30,
  bottom: 30,
  zIndex: 1000,
  cursor: "pointer",
}));

// Prayer Time Card
const PrayerTimeCard = styled(motion.div)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.95)",
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  boxShadow: "0 10px 40px rgba(0,0,0,0.1)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255,255,255,0.2)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

// Video Grid Item
const VideoGridItem = styled(motion.div)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  overflow: "hidden",
  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
  transition: "all 0.3s ease",
  background: "#fff",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
  },
}));

const LandingPage = () => {
  const { searchQuery } = useSearch();
  const navigate = useNavigate();

  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const storedMode = localStorage.getItem("themeMode");
  const [mode, setMode] = useState(storedMode || "light");
  const [openModal, setOpenModal] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [openPrayerModal, setOpenPrayerModal] = useState(false);
  const [prayerTimings, setPrayerTimings] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const toggleColorMode = () => {
    const newMode = mode === "light" ? "dark" : "light";

    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  const categories = ["Hadith"];
  const {
    data: videos,
    isLoading,
    refetch,
  } = useQuery(
    ["videos", currentPage, itemsPerPage, searchQuery],
    () => fetchUnauthVideos(currentPage, itemsPerPage, searchQuery),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: currentPage !== undefined,
    }
  );

  const filteredVideos = videos?.videos || [];

  useEffect(() => {
    const savedIndex = localStorage.getItem("videoIndex");
    const lastWatchedDate = localStorage.getItem("lastWatchedDate");
    const today = new Date().toISOString().split("T")[0];

    if (!lastWatchedDate || lastWatchedDate !== today) {
      const nextIndex = savedIndex
        ? (parseInt(savedIndex) + 1) % (videos?.videos?.length || 1)
        : 0;
      setCurrentVideoIndex(nextIndex);
      localStorage.setItem("videoIndex", nextIndex);
      localStorage.setItem("lastWatchedDate", today);
    } else {
      setCurrentVideoIndex(savedIndex ? parseInt(savedIndex) : 0);
    }
  }, [videos]);

  const currentVideo = videos?.videos?.[currentVideoIndex];

  const fetchPrayerTimings = async (latitude, longitude) => {
    const response = await fetch(
      `https://api.aladhan.com/v1/timings?latitude=${latitude}&longitude=${longitude}&method=2`
    );
    const data = await response.json();
    return data.data.timings;
  };

  const handlePrayerTimings = async () => {
    try {
      if (navigator.geolocation) {
        // Attempt to get the current location
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            const timings = await fetchPrayerTimings(latitude, longitude);
            setPrayerTimings({
              Fajr: timings?.Fajr,
              Sunrise: timings?.Sunrise,
              Dhuhr: timings?.Dhuhr,
              Asr: timings?.Asr,
              Sunset: timings?.Sunset,
              Maghrib: timings?.Maghrib,
              Isha: timings?.Isha,
            });
            setOpenPrayerModal(true);
          },
          (error) => {
            console.error("Geolocation error:", error);
            if (error.code === error.PERMISSION_DENIED) {
              alert(
                "Location permission denied. Please enable location access for accurate prayer timings."
              );
              // Optionally, provide a message about how to enable it manually
              alert(
                "To enable location access, please go to your browser's settings and allow access to your location."
              );
            } else if (error.code === error.POSITION_UNAVAILABLE) {
              alert("Unable to retrieve location. Please try again later.");
            } else if (error.code === error.TIMEOUT) {
              alert(
                "The request to get user location timed out. Please try again."
              );
            } else {
              alert("An unknown error occurred. Please try again.");
            }
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
      } else {
        alert("Geolocation is not supported by your browser.");
      }
    } catch (error) {
      console.error("Failed to fetch prayer timings", error);
      alert(
        "There was an issue fetching prayer timings. Please try again later."
      );
    }
  };

  const handleReady = () => setLoading(false);
  const handleBuffer = () => setLoading(true);

  const convertToDate = (timeStr) => {
    const [time, period] = timeStr.split(" ");
    const [hours, minutes] = time.split(":");
    const date = new Date();

    let hour = parseInt(hours);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }

    date.setHours(hour, parseInt(minutes), 0);
    return date;
  };

  // Function to determine the next prayer
  const isNextPrayer = (prayerName) => {
    if (!prayerTimings) return false;

    const now = new Date();
    const currentTime = now.getTime();

    // Convert prayer times to Date objects and create a sorted array
    const prayerTimes = Object.entries(prayerTimings).map(([prayer, time]) => ({
      prayer,
      time: convertToDate(time).getTime(),
    }));

    // Sort prayer times
    prayerTimes.sort((a, b) => a.time - b.time);

    // Find the next prayer
    let nextPrayer = prayerTimes.find((prayer) => prayer.time > currentTime);

    // If no next prayer found today, the next prayer is the first prayer of tomorrow
    if (!nextPrayer) {
      nextPrayer = prayerTimes[0];
    }

    return nextPrayer.prayer.toLowerCase() === prayerName.toLowerCase();
  };

  // Optional: Add a function to get remaining time until next prayer
  const getTimeUntilNextPrayer = () => {
    if (!prayerTimings) return null;

    const now = new Date();
    const currentTime = now.getTime();

    const prayerTimes = Object.entries(prayerTimings).map(([prayer, time]) => ({
      prayer,
      time: convertToDate(time).getTime(),
    }));

    prayerTimes.sort((a, b) => a.time - b.time);

    let nextPrayer = prayerTimes.find((prayer) => prayer.time > currentTime);

    // If no next prayer found today, the next prayer is the first prayer of tomorrow
    if (!nextPrayer) {
      nextPrayer = prayerTimes[0];
      // Add 24 hours to the time
      nextPrayer.time += 24 * 60 * 60 * 1000;
    }

    const timeDiff = nextPrayer.time - currentTime;
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return {
      prayer: nextPrayer.prayer,
      timeRemaining: `${hours}h ${minutes}m`,
    };
  };

  // You can then use this in your prayer time display
  // Add this to your prayer time card JSX
  const nextPrayerInfo = getTimeUntilNextPrayer();

  return (
    <ThemeProvider theme={theme}>
      <MainHeader toggleColorMode={toggleColorMode} />
      
      {/* Floating Donate Button */}
      <FloatingDonateButton
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Button
          variant="contained"
          onClick={() => navigate("/donation")}
          sx={{
            background: "linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)",
            color: "white",
            borderRadius: "30px",
            padding: "15px 30px",
            fontSize: "1.1rem",
            fontWeight: 600,
            boxShadow: "0 8px 25px rgba(0,176,155,0.4)",
            "&:hover": {
              boxShadow: "0 12px 35px rgba(0,176,155,0.6)",
            },
          }}
        >
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
            }}
          >
            🤲 Donate Now
          </motion.div>
        </Button>
      </FloatingDonateButton>

      {/* Hero Section */}
      <StyledHero>
        <VideoBackground 
          autoPlay 
          muted 
          loop 
          playsInline
          style={{ objectFit: "cover" }}
        >
          <source
            src="/assets/videos/7401908-hd_1920_1080_25fps.mp4"
            type="video/mp4"
          />
        </VideoBackground>
        <Container 
          sx={{ 
            position: "relative", 
            zIndex: 2,
            py: { xs: 4, md: 6 }
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography 
              variant="h1" 
              sx={{ 
                fontWeight: 800,
                fontSize: { 
                  xs: "2rem",
                  sm: "2.5rem",
                  md: "3.5rem",
                  lg: "4rem"
                },
                textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                mb: { xs: 2, md: 3 }
              }}
            >
              Discover the Beauty of Islam
            </Typography>
            <Typography 
              variant="h5" 
              sx={{ 
                mb: { xs: 3, md: 4 },
                opacity: 0.9,
                maxWidth: "800px",
                margin: "0 auto",
                textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                fontSize: { 
                  xs: "1rem",
                  sm: "1.25rem",
                  md: "1.5rem"
                }
              }}
            >
              Join our growing community of seekers and learners on the path of knowledge
            </Typography>
            
            {/* Main CTA Buttons */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 2, sm: 3 }}
              justifyContent="center"
              sx={{ mt: { xs: 3, md: 4 } }}
            >
              <Button
                component={motion.button}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                variant="contained"
                size="large"
                onClick={() => navigate("/donation")}
                sx={{
                  background: "linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)",
                  color: "white",
                  borderRadius: "50px",
                  padding: { 
                    xs: "12px 30px",
                    md: "15px 40px"
                  },
                  fontSize: { 
                    xs: "1rem",
                    md: "1.2rem"
                  },
                  fontWeight: 600,
                  textTransform: "none",
                  boxShadow: "0 8px 25px rgba(0,176,155,0.4)",
                  "&:hover": {
                    boxShadow: "0 12px 35px rgba(0,176,155,0.6)",
                  },
                }}
              >
                Support Our Mission
                <motion.span
                  style={{ marginLeft: "10px", display: "inline-block" }}
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1 }}
                >
                  →
                </motion.span>
              </Button>
              
              <Button
                variant="outlined"
                size="large"
                onClick={handlePrayerTimings}
                sx={{
                  borderRadius: "50px",
                  padding: { 
                    xs: "12px 30px",
                    md: "15px 40px"
                  },
                  fontSize: { 
                    xs: "1rem",
                    md: "1.2rem"
                  },
                  fontWeight: 600,
                  textTransform: "none",
                  borderColor: "white",
                  color: "white",
                  "&:hover": {
                    borderColor: "white",
                    background: "rgba(255,255,255,0.1)",
                  },
                }}
              >
                <AccessTimeIcon sx={{ mr: 1 }} /> Prayer Times
              </Button>
            </Stack>
          </motion.div>
        </Container>
      </StyledHero>

      {/* Featured Videos Section */}
      <Container maxWidth="xl" sx={{ py: 8 }}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            fontWeight: 700,
            mb: 6,
            background: "linear-gradient(45deg, #00b09b 30%, #96c93d 90%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Featured Videos
        </Typography>

        <Grid container spacing={4}>
          {filteredVideos?.map((video, index) => (
            <Grid item xs={12} sm={6} md={3} key={video?.id}>
              <VideoGridItem
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <CardComponent
                  module={video}
                  refetch={refetch}
                  path="videoDetail"
                  showFavourite={false}
                />
              </VideoGridItem>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Featured Section */}
      <Box sx={{ pb: 8, bgcolor: "background.default" }}>
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            {/* Statistics */}
            <Grid item xs={12} md={12}>
            <Stack
              direction="row"
              spacing={2}
            
              backgroundColor="aliceblue"
              p={3}
            >
              <Stack direction="column" spacing={2}>
                <Typography variant="h2" component="h1" gutterBottom>
                  Welcome to Islam InfoTube
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Discover the beauty of Islam through videos
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box
                  sx={{
                    mt: 3,
                    p: 3,
                    bgcolor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Support Our Mission
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Your donation helps us spread Islamic knowledge and wisdom
                    to millions worldwide.
                  </Typography>
                  <Button
          variant="contained"
          onClick={() => navigate("/donation")}
          sx={{
            background: "linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)",
            color: "white",
            textTransform: "none",
            borderRadius: "30px",
            padding: "15px 30px",
            fontSize: "1.1rem",
            fontWeight: 600,
            boxShadow: "0 8px 25px rgba(0,176,155,0.4)",
            "&:hover": {
              boxShadow: "0 12px 35px rgba(0,176,155,0.6)",
            },
          }}
        >
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
            }}
          >
            🤲 Donate Now
          </motion.div>
        </Button>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={4}
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 6 }}
            >
              {[
                { count: "1000+", label: "Islamic Videos" },
                { count: "500K+", label: "Monthly Views" },
                { count: "100K+", label: "Community Members" },
              ].map((stat, index) => (
                <Box
                  key={index}
                  sx={{
                    textAlign: "center",
                    p: 3,
                    borderRadius: 2,
                    width: "100%",
                    bgcolor: "background.paper",
                    boxShadow: 1,
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-5px)",
                    },
                  }}
                >
                  <Typography variant="h3" color="green" gutterBottom>
                    {stat.count}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {stat.label}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Grid>

          {/* Categories Section */}
          {categories.map((category) => (
            <Grid item xs={12} key={category}>
              {/* <Typography
                variant="h4"
                component="h2"
                gutterBottom
                sx={{
                  textAlign: "center",
                  mb: 4,
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: -8,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: 60,
                    height: 3,
                    bgcolor: "primary.main",
                    borderRadius: 1,
                  },
                }}
              >
                {category}
              </Typography> */}
              <Grid container spacing={3}>
                {filteredVideos?.map((video, index) => (
                  <MotionGrid
                    item
                    key={video?.id}
                    xs={12}
                    sm={6}
                    md={3}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <CardComponent
                      module={video}
                      refetch={refetch}
                      path="videoDetail"
                      showFavourite={false}
                    />
                  </MotionGrid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          py: 8,
          background: "linear-gradient(135deg, #45a049 0%, #4CAF50 100%)",
          color: "white",
          textAlign: "center",
        }}
      >
        <Container>
          <Typography variant="h4" gutterBottom>
            Join Our Growing Community
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 4 }}>
            Subscribe to receive daily Islamic reminders and updates
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            justifyContent="center"
            sx={{ maxWidth: 600, mx: "auto" }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter your email"
              fullWidth
              sx={{
                bgcolor: "white",
                borderRadius: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "transparent" },
                },
              }}
            />
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                bgcolor: "#FFD700",
                color: "#000",
                "&:hover": { bgcolor: "#FFA500" },
                px: 4,
              }}
            >
              Subscribe
            </Button>
          </Stack>
        </Container>
      </Box>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            minHeight: "70vh", // Set a minimum height for the dialog
          },
        }}
      >
        <DialogTitle
          sx={{
            position: "relative",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            mb: 0,
          }}
        >
          Hadith of the Day
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            px: 2, // Remove padding
            height: "100%", // Fixed height for video container
            position: "relative", // Required for absolute positioning of player
            // bgcolor: "black", // Better looking background for video
          }}
        >
          <Box
            sx={{
              position: "relative",
              paddingTop: "56.25%", // 16:9 Aspect Ratio
            }}
          >
            <ReactPlayer
              url={currentVideo?.videos3link}
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              controls={true}
              playing={true} // Auto play when opened
              muted={false}
              onReady={handleReady}
              onBuffer={handleBuffer}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openPrayerModal}
        onClose={() => setOpenPrayerModal(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 3,
            background: "linear-gradient(135deg, #f6f8fd 0%, #f1f4f9 100%)",
          },
        }}
      >
        <DialogTitle
          sx={{
            position: "relative",
            textAlign: "center",
            mb: 2,
            background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
            color: "white",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Prayer Times
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, opacity: 0.9 }}>
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Typography>
          <IconButton
            onClick={() => setOpenPrayerModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              "&:hover": {
                background: "rgba(255,255,255,0.1)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },

            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "green",
              borderRadius: "20px",
              border: "2px solid #f1f1f1",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#FF00B3",
            },
          }}
        >
          {prayerTimings ? (
            <Box>
              <Grid container spacing={3}>
                {Object.entries(prayerTimings).map(([key, time], index) => (
                  <Grid item xs={12} sm={6} md={4} key={key}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <Box
                        sx={{
                          p: 3,
                          height: "100%",
                          borderRadius: 4,
                          position: "relative",
                          overflow: "hidden",
                          background: getPrayerGradient(key),
                          boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.15)",
                          backdropFilter: "blur(4px)",
                          border: "1px solid rgba(255, 255, 255, 0.18)",
                          "&:hover": {
                            transform: "translateY(-8px)",
                            transition: "transform 0.3s ease-in-out",
                            "& .prayer-icon": {
                              transform: "scale(1.1) rotate(10deg)",
                            },
                          },
                        }}
                      >
                        <Stack spacing={2} alignItems="center">
                          <motion.div
                            className="prayer-icon"
                            whileHover={{ scale: 1.1, rotate: 10 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            <Box
                              sx={{
                                width: 64,
                                height: 64,
                                display: "flex",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "rgba(255, 255, 255, 0.9)",
                                boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                                transition: "all 0.3s ease-in-out",
                              }}
                            >
                              {prayerIcons(key)}
                            </Box>
                          </motion.div>

                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 600,
                              color: "text.primary",
                              textTransform: "capitalize",
                              textAlign: "center",
                            }}
                          >
                            {key}
                          </Typography>

                          <Box
                            sx={{
                              p: 1.5,
                              borderRadius: 2,
                              background: "rgba(255, 255, 255, 0.9)",
                              boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: 700,
                                color: "primary.dark",
                                fontFamily: "digital-clock",
                                letterSpacing: 1,
                              }}
                            >
                              {time}
                            </Typography>
                          </Box>

                          {isNextPrayer(key) && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: 16,
                                right: 16,
                                px: 1.5,
                                py: 0.5,
                                borderRadius: "pill",
                                background: "rgba(25, 118, 210, 0.9)",
                                color: "white",
                                fontSize: "0.75rem",
                                fontWeight: 600,
                              }}
                            >
                              <Stack direction="column" alignItems="center">
                                <Typography
                                  variant="caption"
                                  sx={{ fontWeight: 700 }}
                                >
                                  NEXT PRAYER
                                </Typography>
                                <Typography variant="caption">
                                  {nextPrayerInfo?.timeRemaining}
                                </Typography>
                              </Stack>
                            </Box>
                          )}
                        </Stack>
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ) : (
            <Box sx={{ p: 5, textAlign: "center" }}>
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              >
                <CircularProgress size={50} />
              </motion.div>
              <Typography sx={{ mt: 2, color: "text.secondary" }}>
                Loading prayer timings...
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions width="100%">
          {" "}
          <Box
            sx={{
              p: 1,
              width: "100%",
              borderRadius: 2,
              background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
              color: "white",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
              Current Time
            </Typography>
            <Typography variant="h3" sx={{ fontFamily: "digital-clock" }}>
              {new Date().toLocaleTimeString()}
            </Typography>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default LandingPage;
