import React, { useMemo, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getAllPlans, stripeCheckout } from "./APIS/users";
import { getUser } from "./layout/Sidebar";

const DonationModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const userEmail = localStorage.getItem("userEmail"); // Retrieve stored email
  const user  = getUser()

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await getAllPlans();
        setPlans(response);
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const formik = useFormik({
    initialValues: {
      frequency: "monthly",
      selectedAmount: "",
      amount: "",
      currency: "USD",
      email: userEmail || "",
      selectedPlan: null,
    },
    onSubmit: async (values) => {
      const payload = {
        email: values?.email,
        amount: values?.amount,
        isRecurring: values?.frequency === "monthly",
        planId: values?.selectedPlan?.id,
        userId: user?.id
      };
 
      const response = await stripeCheckout(payload);
      window.open(response?.stripe_checkout_session_url, "_blank");
    },
  });

  const currencySymbol = useMemo(() => {
    const symbols = { USD: "$", EUR: "€", GBP: "£" };
    return symbols[formik.values.currency] || "$";
  }, [formik.values.currency]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: { borderRadius: "20px" },
        component: motion.div,
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 20 },
      }}
    >
      <DialogTitle sx={{ position: "relative", textAlign: "center", pb: 0 }}>
        <motion.img
          src="/logo.png"
          alt="Logo"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
          style={{ maxWidth: "80px", marginBottom: "16px" }}
        />
        <IconButton onClick={onClose} aria-label="close" sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" align="center" gutterBottom>
          Make a Donation
        </Typography>
        <ToggleButtonGroup
          value={formik.values.frequency}
          exclusive
          onChange={(e, value) => value && formik.setFieldValue("frequency", value)}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap:2,
            mb: 3,
            "& .MuiToggleButton-root": {
              flex: 1,
              borderRadius: "20px",
           
              textTransform: "none",
              fontSize: "16px",
              color: "#000",
              border: "none",
              backgroundColor: "#E3F2FD",
              "&.Mui-selected": {
                backgroundColor: "#00b09b",
                color: "#fff",
              },
            },
          }}
        >
          <ToggleButton value="one-time">One time</ToggleButton>
          <ToggleButton value="monthly">Monthly</ToggleButton>
        </ToggleButtonGroup>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form" onSubmit={formik.handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {/* Email Field - Only Show if User is Not Logged In */}
            {!userEmail && (
              <TextField
                fullWidth
                label="Your Email"
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                required
                sx={{ backgroundColor: "#e0faf0", borderRadius: "10px" }}
              />
            )}

            {/* Subscription Plans - Only Show if Monthly Payment is Selected */}
            {formik.values.frequency === "monthly" && (
              <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: 2, mb: 2 }}>
                {plans?.map((plan) => (
                  <motion.div key={plan.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Button
                      fullWidth
                      variant={formik.values.selectedPlan?.id === plan.id ? "contained" : "outlined"}
                      onClick={() => {
                        formik.setFieldValue("selectedPlan", plan);
                        formik.setFieldValue("amount", plan.planPrice);
                        formik.setFieldValue("selectedAmount", plan.planPrice);
                      }}
                      sx={{
                        borderRadius: "10px",
                        background: formik.values.selectedPlan?.id === plan.id
                          ? "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)"
                          : "#e0faf0",
                        "&:hover": {
                          backgroundColor: formik.values.selectedPlan?.id === plan.id ? "#00796b" : "#b2f2e4",
                        },
                      }}
                    >
                      {currencySymbol}
                      {plan.planPrice}
                    </Button>
                  </motion.div>
                ))}
              </Box>
            )}

            {/* Custom Amount Field */}
            <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
              <Select
                value={formik.values.currency}
                onChange={(e) => formik.setFieldValue("currency", e.target.value)}
                sx={{ minWidth: "100px", backgroundColor: "#e0faf0", borderRadius: "10px" }}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
              </Select>
              <TextField
                fullWidth
                placeholder="Enter amount"
                value={formik.values.amount}
                onChange={(e) => {
                  formik.setFieldValue("amount", e.target.value);
                  formik.setFieldValue("selectedPlan", null);
                }}
                name="amount"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                  style: { backgroundColor: "#e0faf0", borderRadius: "10px" },
                }}
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "10px" } }}
              />
            </Box>

            {/* Submit Button */}
            <Button
              component={motion.button}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              variant="contained"
              fullWidth
              disabled={!formik.values.amount || !formik.values.email}
              type="submit"
              sx={{
                mt: 2,
                background: "linear-gradient(90deg, #00b09b 0%, #96c93d 100%)",
                color: "#fff",
                borderRadius: "50px",
                padding: "12px 32px",
              }}
            >
              Continue
            </Button>
          </Box>
        )}

        {/* FAQs Link */}
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Button variant="text" sx={{ textTransform: "none", color: "#00b09b" }}>
            FAQs
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DonationModal;
