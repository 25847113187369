import React, { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Container,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: '#dc3545', // Red color for error
    },
    secondary: {
      main: '#6c757d',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

const Error = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically redirect after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        sx={{
          background: 'linear-gradient(135deg, #fff5f5 0%, #ffe5e5 100%)',
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <Container component="main" maxWidth="md">
          <Paper
            component={motion.div}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            elevation={6}
            sx={{
              p: { xs: 3, md: 5 },
              borderRadius: "20px",
              background: 'linear-gradient(135deg, #ffffff 0%, #fff8f8 100%)',
              boxShadow: "0 10px 30px rgba(220, 53, 69, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.5,
                }}
              >
                <ErrorOutlineIcon 
                  sx={{ 
                    fontSize: 80, 
                    color: "primary.main",
                    mb: 2,
                  }} 
                />
              </motion.div>

              <Typography
                variant="h3"
                component={motion.h1}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.7 }}
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  color: "primary.main",
                  textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                }}
              >
                Payment Failed
              </Typography>

              <Typography
                variant="h6"
                component={motion.p}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.9 }}
                sx={{
                  mb: 4,
                  color: "text.secondary",
                  lineHeight: 1.8,
                  maxWidth: "700px",
                }}
              >
                We apologize, but there was an issue processing your donation. No charges were made to your account. Please try again or contact your bank if the problem persists.
              </Typography>

              <Box
                component={motion.div}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 1.1 }}
                sx={{
                  display: 'flex',
                  gap: 2,
                  width: "100%",
                  maxWidth: "400px",
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
              >
                <Button
                  component={motion.button}
                  whileHover={{ 
                    scale: 1.05,
                    boxShadow: "0 10px 20px rgba(220, 53, 69, 0.2)"
                  }}
                  whileTap={{ scale: 0.95 }}
                  variant="contained"
                  onClick={() => navigate('/donation')}
                  sx={{
                    py: 2,
                    flex: 1,
                    background: "linear-gradient(90deg, #dc3545 0%, #c82333 100%)",
                    color: "#fff",
                    borderRadius: "50px",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    position: "relative",
                    overflow: "hidden",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: "-100%",
                      width: "200%",
                      height: "100%",
                      background: "linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)",
                      animation: "shimmer 2s infinite",
                    },
                    "@keyframes shimmer": {
                      "0%": { left: "-100%" },
                      "100%": { left: "100%" }
                    },
                  }}
                >
                  Try Again
                </Button>

                <Button
                  component={motion.button}
                  whileHover={{ 
                    scale: 1.05,
                    boxShadow: "0 10px 20px rgba(108, 117, 125, 0.2)"
                  }}
                  whileTap={{ scale: 0.95 }}
                  variant="outlined"
                  onClick={() => navigate('/')}
                  sx={{
                    py: 2,
                    flex: 1,
                    borderColor: "secondary.main",
                    color: "secondary.main",
                    borderRadius: "50px",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  Return Home
                </Button>
              </Box>

              <Typography
                variant="body2"
                component={motion.p}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.3 }}
                sx={{
                  mt: 3,
                  color: "text.secondary",
                }}
              >
                Redirecting to home page in 5 seconds...
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Error;