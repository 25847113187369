import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, alpha } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../AxiosInstance";
import { motion } from "framer-motion";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff, Email } from '@mui/icons-material';

export const buttonStyles = {
  backgroundColor: "#009900",
  color: "#FFF",
  "&:hover": {
    backgroundColor: "#009900",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#00b09b',
      light: '#4ce3c2',
      dark: '#008073',
    },
    secondary: {
      main: '#96c93d',
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/" sx={{ 
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' }
      }}>
        HadeesTube
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      {" | "}
      <Link color="inherit" href="/privacy-policy" sx={{ 
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline' }
      }}>
        Privacy Policy
      </Link>
    </Typography>
  );
}

export const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const loginData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    try {
      const response = await axiosInstance.post("auth/signin", loginData);
      localStorage.setItem("authToken", response.data.access_token);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("role", JSON.stringify(response.data.role));
      localStorage.setItem("permissions", JSON.stringify(response.data.permissions));
      
      const role = response.data.role?.name.toLowerCase();
      navigate(role === "user" ? "/home" : "/admin/videos");
      toast.success("Successfully logged in");
    } catch (error) {
      const errorResponse = error?.response?.data;
      toast.error(errorResponse?.message || "Error during login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper
          component={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          elevation={3}
          sx={{
            mt: 8,
            p: 4,
            borderRadius: 3,
            background: 'linear-gradient(145deg, #ffffff 0%, #f4f4f4 100%)',
            boxShadow: '0 8px 32px rgba(0, 176, 155, 0.1)',
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
            >
              <Avatar
                sx={{
                  m: 1,
                  bgcolor: 'transparent',
                  width: 80,
                  height: 80,
                }}
              >
                <img src="/logo.png" alt="logo" style={{ width: '100%', height: '100%' }} />
              </Avatar>
            </motion.div>

            <Typography component="h1" variant="h5" sx={{ 
              mt: 2,
              color: theme.palette.primary.main,
              fontWeight: 600,
              letterSpacing: 1,
            }}>
              Welcome Back
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email sx={{ color: theme.palette.primary.main }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }
                }}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox 
                    value="remember" 
                    color="primary"
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.primary.main,
                      }
                    }}
                  />
                }
                label="Remember me"
                sx={{ mt: 1 }}
              />

              <Button
                component={motion.button}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  background: 'linear-gradient(45deg, #00b09b 30%, #96c93d 90%)',
                  borderRadius: 2,
                  color: 'white',
                  fontWeight: 600,
                  textTransform: 'none',
                  fontSize: '1rem',
                  boxShadow: '0 4px 20px rgba(0, 176, 155, 0.25)',
                  '&:hover': {
                    boxShadow: '0 6px 25px rgba(0, 176, 155, 0.35)',
                  }
                }}
              >
                {loading ? "Signing in..." : "Sign In"}
              </Button>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs>
                  <Link 
                    href="#" 
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link 
                    href="/signup" 
                    variant="body2"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' }
                    }}
                  >
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
