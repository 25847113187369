import axios from "axios";

// Set baseURL conditionally
const baseURL = process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_BACKEND_URL :
                process.env.REACT_APP_BACKEND_URL;

// Create a reusable axios instance
const axiosInstance = axios.create({
    baseURL: baseURL
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        // Set default headers for all requests
        config.headers['Content-Type'] = 'application/json';
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const axiosInstanceUnauth = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
}); 

// Add response interceptor with better error handling
axiosInstanceUnauth.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.error('Axios unauth error:', {
            status: error.response?.status,
            message: error.message,
            config: error.config
        });
        return Promise.reject(error);
    }
);

// Add request interceptor for debugging
axiosInstanceUnauth.interceptors.request.use(
    (config) => {
        // Log the request for debugging
        console.log('Making unauth request to:', config.url);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (data.hasOwnProperty('AccessToken')) {
            response.config.headers.Authorization = `Bearer ${data.AccessToken}`;
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const axiosInstanceForFile = axios.create({
    baseURL: baseURL
});

// Request interceptor for axios File
axiosInstanceForFile.interceptors.request.use(
    (config) => {
        // Set default headers for all requests
        config.headers['Content-Type'] = 'multipart/form-data';
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor for axios File
axiosInstanceForFile.interceptors.response.use(
    (response) => {
        const data = response.data;
        if (data.hasOwnProperty('AccessToken')) {
            response.config.headers.Authorization = `Bearer ${data.AccessToken}`;
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export { axiosInstance, axiosInstanceForFile, axiosInstanceUnauth };
