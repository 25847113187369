import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TelegramIcon,
} from "react-share";
// Import Material Icons for platforms without built-in icons
import XIcon from '@mui/icons-material/X'; // New X icon

// Custom social media icons for platforms without built-in components
const SocialIcon = ({ icon, onClick, label, bgColor, isImage }) => (
  <Box
    sx={{
      cursor: 'pointer',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    }}
  >
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          width: 50,
          height: 50,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: bgColor,
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'translateY(-2px)',
          },
          overflow: 'hidden', // Added for image containment
        }}
      >
        {isImage ? (
          <img 
            src={icon} 
            alt={label}
            style={{
              width: '55%',
              height: '55%',
              objectFit: 'contain'
            }}
          />
        ) : icon}
      </Box>
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        {label}
      </Typography>
    </Box>
  </Box>
);

export const SocialMediaIconsModal = ({
  module,
  showSocialMediaModal,
  setShowSocialMediaModal,
}) => {
  const moduleLink = `https://islaminfotube.com/${module?.id}`;
  const shareText = "Check out this amazing content on IslamInfoTube: ";
  const hashTags = ["IslamicKnowledge", "Islam", "Education"];

  const handleXShare = () => {
    const xShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(moduleLink)}&hashtags=${hashTags.join(',')}`;
    window.open(xShareUrl, '_blank', 'width=600,height=600');
  };

  const handleInstagramShare = () => {
    navigator.clipboard.writeText(`${shareText}\n${moduleLink}\n\n#${hashTags.join(' #')}`);
    alert('Link copied! You can now share it on Instagram');
  };

  const handleTikTokShare = () => {
    navigator.clipboard.writeText(`${shareText}\n${moduleLink}\n\n#${hashTags.join(' #')}`);
    alert('Link copied! You can now share it on TikTok');
  };

  const handleTelegramShare = () => {
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(moduleLink)}&text=${encodeURIComponent(shareText)}`;
    window.open(telegramUrl, '_blank', 'width=600,height=600');
  };

  return (
    <Dialog
      open={showSocialMediaModal}
      onClose={() => setShowSocialMediaModal(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
          Share this content
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setShowSocialMediaModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Share this valuable content with your friends and family
        </Typography>
        
        <Box 
          display="grid" 
          gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))"
          gap={3}
          sx={{ mb: 3 }}
        >
          <FacebookShareButton url={moduleLink} quote={shareText} hashtag={hashTags[0]}>
            <SocialIcon
              icon={<FacebookIcon size={32} round />}
              label="Facebook"
              bgColor="#1877F2"
            />
          </FacebookShareButton>

          {/* <Box onClick={handleXShare}>
            <SocialIcon
              icon={<XIcon sx={{ color: 'white', fontSize: 32 }} />}
              label="X"
              bgColor="#000000"
            />
          </Box> */}

          <WhatsappShareButton url={moduleLink} title={shareText}>
            <SocialIcon
              icon={<WhatsappIcon size={32} round />}
              label="WhatsApp"
              bgColor="#25D366"
            />
          </WhatsappShareButton>

          <LinkedinShareButton url={moduleLink} title={shareText}>
            <SocialIcon
              icon={<LinkedinIcon size={32} round />}
              label="LinkedIn"
              bgColor="#0A66C2"
            />
          </LinkedinShareButton>

          {/* <Box onClick={handleTelegramShare}>
            <SocialIcon
              icon={<TelegramIcon size={32} round />}
              label="Telegram"
              bgColor="#0088cc"
            />
          </Box> */}

          <Box onClick={handleInstagramShare}>
            <SocialIcon
              icon="/assets/images/instagram.jpeg"
              label="Instagram"
              bgColor="#FFFF"
              isImage
            />
          </Box>

          <Box onClick={handleTikTokShare}>
            <SocialIcon
              icon="/assets/images/tiktok.jpeg"
              label="TikTok"
              bgColor="#FFFF"
              isImage
            />
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />
        
        <Box sx={{ mt: 2 }}>
          <Typography variant="caption" color="text.secondary">
            Help spread knowledge and earn rewards. Share now! 🌟
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
