import React, { useState } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import {
  Box,
  Toolbar,
  IconButton,
  Button,
  Tooltip,
  Stack,
  Container,
  useScrollTrigger,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { useNavigate } from "react-router-dom";
import ToggleColorMode from "../../ToggleColorMode";
import { VideoSearch } from "../custom-components/VideoSearch";
import { getRole } from "./Sidebar";
const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background:
    theme.palette.mode === "light"
      ? "linear-gradient(to right, #43A047, #1B5E20)" // Green gradient for light mode
      : "linear-gradient(to right, #2E7D32, #1B5E20)", // Darker green gradient for dark mode
  backdropFilter: "blur(8px)",
  boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
  color: "#fff", // Always white text for better contrast
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: "0.95rem",
  fontWeight: 500,
  padding: "8px 20px",
  borderRadius: "50px",
  color: "#fff", // Always white text
  background: "rgba(255, 255, 255, 0.1)",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
  },
}));

const LogoMotion = styled(motion.div)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

export const MainHeader = ({ toggleColorMode }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("authToken")
  );

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    navigate("/");
  };

  const handleDashboardClick = () => {
    const role = getRole();
 
    if (role?.name === "User") {
      navigate("/home");
    } else {
      navigate("/admin/videos");
    }
  };

  const logoVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.5 },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
    tap: { scale: 0.95 },
  };

  return (
    <AppBar position="fixed" trigger={trigger}>
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            py: 1.5,
            display: "grid",
            gridTemplateColumns: "250px 1fr auto",
            gap: 2,
            alignItems: "center",
          }}
        >
          {/* Logo Section */}
          <LogoMotion
            initial="hidden"
            animate="visible"
            variants={logoVariants}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <Box sx={{ width: 50, height: 50 }}>
              <img
                src="/logo.png"
                alt="logo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
                }}
              />
            </Box>
            <Box
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              sx={{
                fontSize: "1.5rem",
                fontWeight: 600,
                color: "#fff", // Always white
                display: { xs: "none", md: "block" },
              }}
            >
              Islam InfoTube
            </Box>
          </LogoMotion>

          {/* Search Section */}
          <Box sx={{ width: "100%", maxWidth: 600, mx: "auto" }}>
            <VideoSearch />
          </Box>

          {/* Navigation Section */}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            component={motion.div}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <Tooltip title="Contribute Sadaqah" arrow>
              <IconButton
                component={motion.button}
                variants={buttonVariants}
                whileHover="hover"
                whileTap="tap"
                onClick={() => navigate("/donation")}
                sx={{
                  color: "#fff",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.1)",
                  },
                  padding: "15px",
                }}
              >
                <VolunteerActivismIcon />
              </IconButton>
            </Tooltip>

            <ToggleColorMode toggleColorMode={toggleColorMode} />

            <AnimatePresence>
              {isLoggedIn ? (
                <>
                  <motion.div
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <StyledButton onClick={handleDashboardClick}>
                      Dashboard
                    </StyledButton>
                  </motion.div>
                  <motion.div
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <StyledButton
                      onClick={handleLogout}
                      sx={{
                        background: alpha(theme.palette.error.main, 0.1),
                        color: theme.palette.error.main,
                        "&:hover": {
                          background: alpha(theme.palette.error.main, 0.2),
                        },
                      }}
                    >
                      Logout
                    </StyledButton>
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <StyledButton onClick={() => navigate("/")}>
                      Home
                    </StyledButton>
                  </motion.div>
                  <motion.div
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    <StyledButton
                      onClick={() => navigate("/login")}
                      sx={{
                        background: "rgba(255, 255, 255, 0.2)",
                        color: "#fff",
                        "&:hover": {
                          background: "rgba(255, 255, 255, 0.3)",
                        },
                      }}
                    >
                      Login
                    </StyledButton>
                  </motion.div>
                </>
              )}
            </AnimatePresence>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
