import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { 
  Grid, Box, Typography, Button, Container, CircularProgress,
  Paper, Tooltip, useTheme, useMediaQuery, Divider
} from "@mui/material";
import { RelatedVideos } from "../RelatedVideos";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DownloadIcon from "@mui/icons-material/Download";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { truncateString } from "../custom-components/TruncateString";
import { SocialMediaIconsModal } from "../custom-components/SocialMediaIconsModal";
import ReactPlayer from "react-player";
import { fetchVideos, makeVideoFavorite, fetchVideo, fetchVideoAouth } from "../APIS/videos";
import { useQuery, useInfiniteQuery } from "react-query";
import { motion, AnimatePresence } from "framer-motion";

const MotionContainer = motion(Container);
const MotionPaper = motion(Paper);

export const VideoPlayer = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showSocialMediaModal, setShowSocialMediaModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 4;

  const { data: video, refetch } = useQuery(["video", id], () => fetchVideoAouth(id));

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: videosLoading,
  } = useInfiniteQuery(
    "relatedVideos",
    ({ pageParam = 1 }) => fetchVideos(pageParam, itemsPerPage),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.videos.length < itemsPerPage) return undefined;
        return pages.length + 1;
      },
    }
  );

  const handleFavoriteToggle = async () => {
    try {
      await makeVideoFavorite(id, !video?.isFavorite);
      refetch();
    } catch (error) {
      console.error("Error adding video to favorites:", error);
    }
  };

  const handleShare = () => setShowSocialMediaModal(true);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = video?.videos3link;
    link.download = `video_${video?.id}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReady = () => setLoading(false);
  const handleBuffer = () => setLoading(true);

  const gradientBackground = 'linear-gradient(135deg, #006400 0%, #32CD32 100%)';
  const yellowGradient = 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)';

  const relatedVideos = data?.pages.flatMap(page => page.videos).filter(item => item.id !== video?.id) || [];

  return (
    <MotionContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      maxWidth="xl"
      disableGutters
      sx={{ px: { xs: 0, sm: 3 } }}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} lg={8}>
          <MotionPaper
            elevation={3}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            sx={{ 
              overflow: 'hidden',
              borderRadius: { xs: 0, sm: 2 },
              background: gradientBackground,
              position: "relative",
              paddingTop: "56.25%", // 16:9 aspect ratio
            }}
          >
            {/* {loading && (
              <CircularProgress
                size={isMobile ? 40 : 60}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                }}
              />
            )} */}
            <ReactPlayer
              url={video?.videos3link}
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
              controls={true}
              muted={false}
              onReady={handleReady}
              onBuffer={handleBuffer}
            />
          </MotionPaper>
          
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2}}>
            <Typography 
              variant={isMobile ? "body" : "h6"} 
              sx={{ 
                fontWeight: isMobile ? 500 : 600, 
                color: theme.palette.text.primary,
               
              }}
            >
              {truncateString(video?.name, isMobile ? 50 : 100)}
            </Typography>
            <Box 
              sx={{ 
                display: "flex", 
                // flexWrap: 'wrap', 
                gap: 1, 
                // mt: 3,
                justifyContent: "center"
              }}
            >
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Tooltip 
                  title={video?.isFavorite ? "Remove from Favorites" : "Add to Favorites"}
                  arrow
                  placement="top"
                >
                  <Button
                    variant="contained"
                    onClick={handleFavoriteToggle}
                    size="small"
                    startIcon={
                      <motion.div
                        animate={video?.isFavorite ? {
                          scale: [1, 1.2, 1],
                          transition: { repeat: Infinity, duration: 1.5 }
                        } : {}}
                      >
                        <FavoriteIcon 
                          fontSize="small"
                          sx={{ color: video?.isFavorite ? '#ff3366' : 'white' }}
                        />
                      </motion.div>
                    }
                    fullWidth={isMobile}
                    
                    sx={{ 
                      background: video?.isFavorite 
                        ? 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)'
                        : 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                      px: 3,
                      py: 1.5,
                      borderRadius: '50px',
                      boxShadow: '0 3px 15px rgba(76, 175, 80, 0.3)',
                      '&:hover': { 
                        background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                        boxShadow: '0 6px 20px rgba(76, 175, 80, 0.4)',
                      },
                      textTransform: 'none',
                      fontWeight: 600,
                      letterSpacing: 0.5,
                    }}
                  >
                    Favorite
                  </Button>
                </Tooltip>
              </motion.div>

              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Tooltip title="Share with others" arrow placement="top">
                  <Button
                    variant="contained"
                    onClick={handleShare}
                    startIcon={
                      <motion.div
                        whileHover={{ rotate: 360 }}
                        transition={{ duration: 0.5 }}
                      >
                        <ShareIcon fontSize="small" />
                      </motion.div>
                    }
                    fullWidth={isMobile}
                    size="small"
                    sx={{ 
                      background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                      px: 3,
                      py: 1.5,
                      borderRadius: '50px',
                      boxShadow: '0 3px 15px rgba(76, 175, 80, 0.3)',
                      '&:hover': { 
                        background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                        boxShadow: '0 6px 20px rgba(76, 175, 80, 0.4)',
                      },
                      textTransform: 'none',
                      fontWeight: 600,
                      letterSpacing: 0.5,
                    }}
                  >
                    Share
                  </Button>
                </Tooltip>
              </motion.div>

              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Tooltip title="Download video" arrow placement="top">
                  <Button
                    variant="contained"
                    onClick={handleDownload}
                    startIcon={
                      <motion.div
                        animate={{
                          y: [0, -3, 0]
                        }}
                        transition={{ repeat: Infinity, duration: 1.5 }}
                      >
                        <DownloadIcon fontSize="small" />
                      </motion.div>
                    }
                    fullWidth={isMobile}
                    size="small"
                    sx={{ 
                      background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                      px: 3,
                      py: 1.5,
                      borderRadius: '50px',
                      boxShadow: '0 3px 15px rgba(76, 175, 80, 0.3)',
                      '&:hover': { 
                        background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                        boxShadow: '0 6px 20px rgba(76, 175, 80, 0.4)',
                      },
                      textTransform: 'none',
                      fontWeight: 600,
                      letterSpacing: 0.5,
                    }}
                  >
                    Download
                  </Button>
                </Tooltip>
              </motion.div>
            </Box>
          </Box>
          
          <Paper 
            elevation={2} 
            sx={{ 
              mt: 3, 
              p: isMobile ? 1.5 : 2, 
              borderRadius: { xs: 0, sm: 2 }, 
              background: 'rgba(0,100,0,0.05)' 
            }}
          >
            <Typography 
              variant={isMobile ? "body2" : "body1"} 
              sx={{ color: theme.palette.text.primary }}
            >
              {video?.hadithText || "No description available."}
            </Typography>
            <Typography 
              variant={isMobile ? "body2" : "body1"} 
              sx={{ color: theme.palette.text.primary }}
            >
              {video?.hadithTextEng }
            </Typography>
          </Paper>
          
          {isMobile && <Divider sx={{ my: 3 }} />}
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box sx={{ position: { xs: 'static', lg: 'sticky' }, top: 16 }}>
            <Typography 
              variant={isMobile ? "h6" : "h5"} 
              sx={{ 
                mb: 2, 
                fontWeight: isMobile ? 600 : 700, 
                color: theme.palette.text.primary, 
                px: { xs: 2, sm: 0 },
                fontSize: isMobile ? '1.1rem' : '1.5rem'
              }}
            >
              Related Videos
            </Typography>
            {videosLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress size={isMobile ? 40 : 60} />
              </Box>
            ) : (
              <>
                <AnimatePresence>
                  {relatedVideos.map((relatedVideo, index) => (
                    <motion.div
                      key={relatedVideo?.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ delay: index * 0.1 }}
                    >
                      <Box sx={{ mb: 2, px: { xs: 2, sm: 0 } }}>
                        <RelatedVideos module={relatedVideo} />
                      </Box>
                    </motion.div>
                  ))}
                </AnimatePresence>
                {hasNextPage && (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                    <Button
                      variant="contained"
                      onClick={() => fetchNextPage()}
                      disabled={isFetchingNextPage}
                      startIcon={<ExpandMoreIcon />}
                      size={isMobile ? "small" : "medium"}
                      sx={{ 
                        background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                        px: 3,
                        py: 1.5,
                        borderRadius: '50px',
                        boxShadow: '0 3px 15px rgba(76, 175, 80, 0.3)',
                        '&:hover': { 
                          background: 'linear-gradient(45deg, #4CAF50 30%, #96c93d 90%)',
                          boxShadow: '0 6px 20px rgba(76, 175, 80, 0.4)',
                        },
                        textTransform: 'none',
                        fontWeight: 600,
                        letterSpacing: 0.5,
                      }}
                    >
                      {isFetchingNextPage ? 'Loading...' : 'Load More'}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Grid>

        <SocialMediaIconsModal
          module={video}
          setShowSocialMediaModal={setShowSocialMediaModal}
          showSocialMediaModal={showSocialMediaModal}
        />
      </Grid>
    </MotionContainer>
  );
};