import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, Card, CardContent, CardMedia, Chip, IconButton } from "@mui/material";
import { truncateString } from "./custom-components/TruncateString";
import moment from "moment";
import { motion } from "framer-motion";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const MotionCard = motion(Card);

export const RelatedVideos = ({ module, unauth }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [duration, setDuration] = useState("0:00");
  const createdAt = moment(module.createdat, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
  const elapsedTime = createdAt.fromNow();

  useEffect(() => {
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src);
      const totalSeconds = Math.floor(video.duration);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      setDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    }

    video.src = module?.videos3link;

    return () => {
      video.remove();
    };
  }, [module?.videos3link]);

  const handleClick = () => {
    if (unauth) {
      navigate(`/videoDetail/${module.id}`);
    } else {
      navigate(`/video/${module.id}`);
    }
  };

  return (
    <MotionCard
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      sx={{
        display: 'flex',
        mb: 2,
        overflow: 'hidden',
        background: 'transparent',
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 200,  // Increased from 168
          height: 112, // Increased from 94 to maintain 16:9 aspect ratio
          borderRadius: 1,
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardMedia
          component="img"
          image={module?.thumbnails3link}
          alt={module?.name}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease-in-out',
            transform: isHovered ? 'scale(1.1)' : 'scale(1)',
          }}
        />
        {isHovered && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(0,0,0,0.5)',
            }}
          >
            <PlayArrowIcon sx={{ fontSize: 48, color: 'white' }} />
          </Box>
        )}
        <Chip
          label={duration}
          size="small"
          sx={{
            position: 'absolute',
            bottom: 4,
            right: 4,
            background: 'rgba(0,0,0,0.75)',
            color: 'white',
          }}
        />
      </Box>
      <CardContent sx={{ flex: 1, p: 1, '&:last-child': { pb: 1 } }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0.5, lineHeight: 1.2 }}>
          {truncateString(module?.name, 50)}
        </Typography>
        {/* <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block', mb: 0.5 }}>
          {module?.channelName || "Channel Name"}
        </Typography> */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <VisibilityIcon sx={{ fontSize: 16, mr: 0.5, color: 'text.secondary' }} />
          <Typography variant="caption" sx={{ color: 'text.secondary', mr: 1 }}>
            {module?.views || "0"} views
          </Typography>
          <AccessTimeIcon sx={{ fontSize: 16, mr: 0.5, color: 'text.secondary' }} />
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {elapsedTime}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {module?.isNew && (
            <Chip 
              label="New" 
              size="small" 
              sx={{ 
                background: 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)', 
                color: 'black' 
              }} 
            />
          )}
          <Box>
            <IconButton size="small" sx={{ color: 'text.secondary' }}>
              <WatchLaterIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" sx={{ color: 'text.secondary' }}>
              <PlaylistAddIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </CardContent>
    </MotionCard>
  );
};