import React, { useState, useEffect } from "react";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { motion } from "framer-motion";
import {
  Box,
  Divider,
  ListItemIcon,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import DownloadIcon from "@mui/icons-material/Download";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from "@mui/icons-material/People";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import { toast } from "react-toastify";

const drawerWidth = 260;
const drawerTop = 70;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  top: drawerTop,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    background: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create(['width', 'transform'], {
      duration: theme.transitions.duration.shorter,
    }),
    width: open ? drawerWidth : theme.spacing(7),
    overflowX: 'hidden',
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, active }) => ({
  margin: '4px 8px',
  borderRadius: '12px',
  transition: 'all 0.2s ease-in-out',
  color: active ? '#026f02' : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: 'rgb(0 128 0 / 8%)',
    transform: 'translateX(4px)',
  },
  ...(active && {
    backgroundColor: 'rgb(0 128 0 / 20%)',
    '&:hover': {
      backgroundColor: 'rgb(0 128 0 / 25%)',
    },
  }),
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.text.secondary,
  padding: theme.spacing(0, 2, 1, 3),
  letterSpacing: '0.5px',
}));

export const getRole = () => {
  const roleString = localStorage.getItem("role");

  return roleString ? JSON.parse(roleString) : null;
};

export const getUser = () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

export const getPermissions = () => {
  const permissionsString = localStorage.getItem("permissions");
  return permissionsString ? JSON.parse(permissionsString) : [];
};

export const Sidebar = ({ open, handleDrawer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [role, setRole] = useState(null);

  useEffect(() => {
    setRole(getRole());
  }, []);

  const handleLogout = () => {
    toast.success("Logged Out Successfully");
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    navigate("/");
  };

  const listItems = [
    { text: "Home", icon: <HomeIcon />, path: "/home" },
    { text: "Shorts", icon: <VideoLibraryIcon />, path: "/shorts" },
    { text: "Offline", icon: <OfflinePinIcon />, path: "/offline" },
    { text: "History", icon: <HistoryIcon />, path: "/history" },
  ];

  const secondaryListItems = [
    { text: "Favorites", icon: <FavoriteIcon />, path: "/favorites" },
    { text: "Downloads", icon: <DownloadIcon />, path: "/downloads" },
    { text: "Settings", icon: <SettingsIcon />, path: "/settings" },
  ];

  const adminItems = {
    Manager: [
      { text: "Videos", icon: <VideoLibraryIcon />, path: "/admin/videos" },
      { text: "Users", icon: <PeopleIcon />, path: "/admin/users" },

    ],

    Student: [
      { text: "Videos", icon: <VideoLibraryIcon />, path: "/admin/videos" },
    ],
    DataEntry: [
      { text: "Videos", icon: <VideoLibraryIcon />, path: "/admin/videos" },
      {
        text: "Upload Video",
        icon: <DownloadIcon />,
        path: "/admin/upload-video",
      },
    ],
    Teacher: [
      { text: "Videos", icon: <VideoLibraryIcon />, path: "/admin/videos" },
    ],
    SuperAdmin: [
      { text: "Videos", icon: <VideoLibraryIcon />, path: "/admin/videos" },
      { text: "Users", icon: <PeopleIcon />, path: "/admin/users" },
      { text: "Student&Teacher", icon: <PeopleIcon />, path: "/admin/student-teacher" },
      {
        text: "Roles",
        icon: <AdminPanelSettingsIcon />,
        path: "/admin/role-and-permissions",
      },
      { text: "Tags", icon: <VideoLibraryIcon />, path: "/admin/tags" },
      {
        text: "Upload Video",
        icon: <DownloadIcon />,
        path: "/admin/upload-video",
      },
    ],
  };

  const renderListItem = (item) => {
    const isActive = location.pathname === item.path;
    
    return (
      <ListItem key={item.text} disablePadding>
        <Tooltip 
          title={!open ? item.text : ""} 
          placement="right"
          arrow
        >
          <StyledListItemButton
            active={isActive ? 1 : 0}
            onClick={() => navigate(item.path)}
            sx={{
              minHeight: 48,
              px: 2.5,
              justifyContent: open ? 'initial' : 'center',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color: isActive ? '#026f02' : 'inherit',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: open ? 1 : 0, x: open ? 0 : -10 }}
              transition={{ duration: 0.2 }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: isActive ? 600 : 400,
                  color: isActive ? '#026f02' : 'inherit',
                  whiteSpace: 'nowrap',
                  opacity: open ? 1 : 0,
                }}
              >
                {item.text}
              </Typography>
            </motion.div>
          </StyledListItemButton>
        </Tooltip>
      </ListItem>
    );
  };

  const getAdminItems = () => {
    if (!role) return [];
    const roleName = role?.name;
    return adminItems[roleName] || [];
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleDrawer}>
            <motion.div
              animate={{ rotate: open ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </motion.div>
          </IconButton>
        </Box>

        <Box sx={{ flexGrow: 1, px: 1 }}>
          {role && role?.name === "User" && (
            <>
              <CategoryLabel>Main Menu</CategoryLabel>
              <List>
                {listItems?.map(renderListItem)}
              </List>
              
              <Divider sx={{ my: 2, opacity: 0.5 }} />
              
              <CategoryLabel>Personal</CategoryLabel>
              <List>
                {secondaryListItems?.map(renderListItem)}
              </List>
            </>
          )}

          {role && role.name !== "User" && (
            <>
              <CategoryLabel>Admin Panel</CategoryLabel>
              <List>
                {getAdminItems()?.map(renderListItem)}
              </List>
            </>
          )}
        </Box>

        <Divider sx={{ opacity: 0.5 }} />
        <Box sx={{ p: 2 }}>
          <StyledListItemButton
            onClick={handleLogout}
            sx={{
              borderRadius: '12px',
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ color: 'inherit', minWidth: 0, mr: open ? 3 : 'auto' }}>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: open ? 1 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <Typography variant="body2">Logout</Typography>
            </motion.div>
          </StyledListItemButton>
        </Box>
      </Box>
    </Drawer>
  );
};
